<template>
	<div
		data-component="study-auction"
		v-if="getIsVisible"
	>
		<div
			v-if="getBids"
			data-element="bids"
			ref="bids"
		>
			<bid-button
				v-for="(bid, i) of getBids"
				:key="i"
				:bid="bid"
				:uuid="uuid"
				:isValid="isValid"
				:isDisabled="getIsBiddingDisabled"
				:isBusy="getIsAuctionBusy"
				:isStudyMode="true"
				:isAnswerDiagram="true"
				:isHighlighted="getIsHighlighted(bid)"
				:isCorrect="getIsBidCorrect(bid)"
				@addBid="handleAddBid"
			/>
		</div>
		<div data-element="special-bids">
			<bid-button
				:bid="getPass"
				:uuid="uuid"
				:isValid="isValid"
				:isDisabled="getIsBiddingDisabled"
				:isBusy="getIsAuctionBusy"
				:showLongPass="true"
				:isStudyMode="true"
				:isAnswerDiagram="true"
				:isHighlighted="getIsHighlighted(getPass)"
				:isCorrect="getIsBidCorrect(getPass)"
				@addBid="handleAddBid"
			/>
			<bid-button
				:bid="getDouble"
				:uuid="uuid"
				:isValid="isValid"
				:isDisabled="getIsBiddingDisabled"
				:isBusy="getIsAuctionBusy"
				:isStudyMode="true"
				:isAnswerDiagram="true"
				:isHighlighted="getIsHighlighted(getDouble)"
				:isCorrect="getIsBidCorrect(getDouble)"
				@addBid="handleAddBid"
			/>
			<bid-button
				:bid="getReDouble"
				:uuid="uuid"
				:isValid="isValid"
				:isDisabled="getIsBiddingDisabled"
				:isBusy="getIsAuctionBusy"
				:isStudyMode="true"
				:isAnswerDiagram="true"
				:isHighlighted="getIsHighlighted(getReDouble)"
				:isCorrect="getIsBidCorrect(getReDouble)"
				@addBid="handleAddBid"
			/>
		</div>
	</div>
</template>

<script>

	import BidButton    from '@/components/simulator/bidding/BidButton';

	export default {
		components: {
			BidButton
		},
		props: {
			uuid: {
				type: String,
				default: undefined
			},
			isValid: {
				type: Boolean,
				default: true
			},
			errors: {
				type: Object,
				default: undefined
			}
		},
		data: () => ({
		}),
		computed: {
			getDiagramIsInModal () {
				return this.$store.getters['quiz/getDiagramIsInModalByUuid']({
					uuid: this.uuid
				});
			},
			getIsBiddingDisabled () {
				return !this.$store.getters['simulator/getIsStudyBiddingModeStarted'];
			},
			getPlayerPosition () {
				return this.$store.getters['simulator/getPlayerPosition'];
			},
			getIsCurrentPlayer () {
				return this.$store.getters['simulator/getPlayerIsCurrentByPosition']({
					position: this.getPlayerPosition
				});
			},
			getIsAuctionBusy () {
				return !this.getIsCurrentPlayer;
			},
			getPass () {
				const pass = {
					bid: 'P',
					type: 'pass'
				};
				return {
					...pass,
					isValid: this.$store.getters['simulator/getCanPass']
				};
			},
			getDouble () {
				const double = {
					bid: 'X',
					type: 'double'
				};
				return {
					...double,
					isValid: this.$store.getters['simulator/getCanDouble']
				};
			},
			getReDouble () {
				const reDouble = {
					bid: 'XX',
					type: 'redouble'
				};
				return {
					...reDouble,
					isValid: this.$store.getters['simulator/getCanReDouble']
				};
			},
			getIsVisible () {
				return !this.getIsAuctionBusy && this.$store.getters['simulator/getStudyAuctionIsVisible'];
			},
			getBids () {
				return this.$store.getters['simulator/getPossibleBids'];
			}
		},
		methods: {
			handleAddBid (bid) {
				this.$store.dispatch('simulator/attemptToBid', {
					bid
				});
			},
			getIsBidCorrect (bid) {
				return this.$store.getters['simulator/getIsBidCorrect'](bid);
			},
			getIsHighlighted (bid) {
				return this.$store.getters['simulator/getIsBidHighlighted'](bid);
			}
		}
	};

</script>

<style lang="scss" scoped>

	[data-component='study-auction'] {
		width:rem(140);
		background-color:$c-white;
		[data-element='bids'] {
			width:100%;
			display:flex;
			flex-wrap:wrap;
		}
		::v-deep [data-component='bid-button'] {
			width:20%; height:rem(22);
			&[data-value='1'] {
				order:7;
			}
			&[data-value='2'] {
				order:6;
			}
			&[data-value='3'] {
				order:5;
			}
			&[data-value='4'] {
				order:4;
			}
			&[data-value='5'] {
				order:3;
			}
			&[data-value='6'] {
				order:2;
			}
			&[data-value='7'] {
				order:1;
			}
			[data-component='bid'] {
				flex-grow:1;
				margin:0;
				height:100%;
				width:100%;
				[data-element='value'] {
					margin-right:0.1em;
					font-size:rem(13);
					line-height:1;
					font-weight:700;
				}
				[data-component='suit-symbol'] {
					[data-component='icon'] {
						width:auto; height:rem(9);
					}
				}
			}
		}
		[data-element='special-bids'] {
			width:100%;
			display:flex;
			::v-deep [data-component='bid-button'] {
				&[data-type='pass'] {
					width:50%;
				}
				&[data-type='double'],
				&[data-type='redouble']{
					width:25%;
				}
			}
		}
	}

</style>
