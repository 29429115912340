<template>
	<portal
		to="stackedOnTopModal"
		v-if="getIsVisible"
	>
		<modal-inner
			@close="handleBackToLesson"
			heading="Deal results"
		>
			<div data-component="deal-results-modal">
				<video-viewer
					v-if="dealRecordingIsVisible"
					:src="getDealRecordingVideoSrc"
				/>
				<div
					v-else
					data-element="table-content"
				>
					<div data-element="deal-diagrams">
						<deal-matrix
							data-element="diagram"
							:gameState="getGameState"
						/>
						<bidding-table
							data-element="diagram"
							:gameState="getGameState"
						/>
					</div>
					<div data-element="deal-results-table">
						<div
							data-element="summary-message"
							v-html="getSummaryMessage"
						/>
						<dynamic-table
							:columns="columns"
							:data="getScores"
						>
							<template #contract="{ cell }">
								<span
									data-element="contract-value"
									v-html="getContractValue(cell)"
								/>
								<suit-symbol
									:suitName="getContractSuit(cell)"
									:withoutPadding="true"
								/>
							</template>
						</dynamic-table>
					</div>
				</div>
				<actions
					:actions="getActions"
					:isWaiting="isWaiting"
					@actionClick="onActionClick"
				/>
			</div>
		</modal-inner>
	</portal>
</template>
<script>

	import ModalInner from '@/components/ui/modal/ModalInner';
	import VideoViewer from '@/components/uploads/VideoViewer';
	import DealMatrix from '@/components/diagrams/DealMatrix';
	import BiddingTable from '@/components/diagrams/BiddingTable';
	import DynamicTable from '@/components/ui/views/dynamicTable/DynamicTable';
	import SuitSymbol from '@/components/diagrams/SuitSymbol';
	import Actions from '@/components/ui/Actions';
	import actionClick from '@/mixins/actionClick';
	import { dealRecordingType, defaultResultMessages, SIMULATOR_MODES } from '@/consts';
	import api from '@/services/api';
	import routeParams from '@/mixins/routeParams';

	export default {
		components: {
			ModalInner,
			VideoViewer,
			DealMatrix,
			BiddingTable,
			DynamicTable,
			SuitSymbol,
			Actions
		},
		mixins: [actionClick, routeParams],
		data () {
			return {
				columns: [
					{
						label: 'Board',
						key: 'index'
					},
					{
						label: 'Contract',
						key: 'contract'
					},
					{
						label: 'By',
						key: 'declarer'
					},
					{
						label: 'Tricks',
						key: 'tricks'
					},
					{
						label: 'We',
						key: 'ourPoints'
					},
					{
						label: 'They',
						key: 'theirPoints'
					}
				],
				scores: [],
				media: [],
				messages: [],
				isWaiting: undefined,
				dealRecordingIsVisible: false
			};
		},
		computed: {
			getGameMode () {
				return this.$store.getters['simulator/getGameMode'];
			},
			getIsStudyMode () {
				return this.getGameMode === SIMULATOR_MODES.STUDY;
			},
			getIsPreviewMode () {
				return this.getGameMode === SIMULATOR_MODES.PREVIEW;
			},
			getDealId () {
				return this.$store.getters['simulator/getGameId'];
			},
			getSessionGameData () {
				return this.$store.getters['simulator/getSessionGameData'];
			},
			getSessionGameIds () {
				return this.getSessionGameData.map(item => item.gameId);
			},
			getSessionGamePositions () {
				return this.getSessionGameData.map(item => item.playerPosition);
			},
			getPlayerPosition () {
				return this.$store.getters['simulator/getPlayerPosition'];
			},
			getIsVisible () {
				return this.$store.getters['simulator/getDealResultsModalIsVisible'];
			},
			getScores () {
				return this.scores.map((item, index) => {
					const scoreBoard = item.scoreBoard;
					const playerPosition = this.getSessionGamePositions[index];
					const weWon = scoreBoard.winner.includes(playerPosition);
					return {
						...scoreBoard,
						index: index + 1,
						ourPoints: weWon ? scoreBoard.points : '',
						theirPoints: weWon ? '' : scoreBoard.points
					};
				});
			},
			getGameState () {
				return this.$store.getters['simulator/getGameState'];
			},
			getGameData () {
				return this.$store.getters['simulator/getGameData'];
			},
			getScoreBoard () {
				return this.getGameData?.scoreBoard;
			},
			getAuctionIsComplete () {
				return this.$store.getters['simulator/getAuctionIsComplete'];
			},
			getGameIsComplete () {
				return this.$store.getters['simulator/getGameIsComplete'];
			},
			getIsNextDealExist () {
				return !!this.$store.getters['simulator/getNextGameState'];
			},
			getIsDealCompleted () {
				return this.getAuctionIsComplete && this.getGameIsComplete;
			},
			getDealRecording () {
				return this.media.find(item => item.config?.type === dealRecordingType);
			},
			getDealRecordingVideoSrc () {
				return this.getDealRecording?.url || '';
			},
			getCurrentDealContract () {
				return this.$store.getters['simulator/getContract'];
			},
			getCurrentDealWinStatus () {
				if (this.getScoreBoard) {
					return this.getScoreBoard.winner.includes(this.getPlayerPosition);
				}
				return false;
			},
			getDealResult () {
				if (this.getCurrentDealContract && this.getScoreBoard) {
					const base = 6; // Base number of tricks to be made by the user
					const contractValue = parseInt(this.getContractValue(this.getCurrentDealContract));
					const tricksToWin = base + contractValue;
					const tricks = this.getScoreBoard.tricks;
					return tricks - tricksToWin;
				}
				return false;
			},
			getSummaryMessage () {
				if (this.getDealResult !== false) {
					if (this.getDealResult < 0) {
						return this.getMessageByDifference('-');
					} else if (this.getDealResult === 0) {
						return this.getMessageByDifference('=');
					} else if (this.getDealResult > 0) {
						return this.getMessageByDifference('+');
					}

					return '';

				}
				return '';
			},
			getActions () {
				return {
					primary: [
						(
							this.getIsDealCompleted ? {
								text: 'Play again',
								actionId: 'handlePlayAgain'
							} : false
						),
						(
							this.getIsNextDealExist ? {
								text: 'Play next deal',
								actionId: 'handlePlayNextDeal'
							} : false
						),
						(
							this.getDealRecording ? {
								text: `${this.dealRecordingIsVisible ? 'Hide' : 'Show'} deal run-through`,
								actionId: 'toggleShowDealRecording'
							} : false
						)
					].filter(Boolean),
					secondary: [
						(
							this.getIsStudyMode ? {
								text: 'Back to lesson page',
								actionId: 'handleBackToLesson'
							} : false
						),
						(
							this.getIsPreviewMode ? {
								text: 'Close',
								actionId: 'close'
							} : false
						)
					].filter(Boolean)
				};
			}
		},
		watch: {
			async getIsDealCompleted (value, oldValue) {
				if (value && !oldValue) {
					await Promise.all([
						this.fetchDealScores(),
						this.fetchDealMedia(),
						this.fetchDealSummaryMessages()
					]);
					this.$store.commit('simulator/setDealResultsModalIsVisible', true);
				}
			}
		},
		methods: {
			getContractValue (contract) {
				if (!contract) {
					return '';
				}
				if (contract[1] === 'N') {
					return `${contract[0]}NT`;
				}
				return contract[0];
			},
			getContractSuit (contract) {
				if (!contract) {
					return '';
				}
				return contract[1];
			},
			getMessageByDifference (difference) {
				const option = this.messages.find(option => option.difference === difference);
				if (option) {
					return option.message;
				}
				return defaultResultMessages[difference];
			},
			async handlePlayAgain () {
				await this.$store.dispatch('simulator/restartGame');
				this.close();
			},
			handlePlayNextDeal () {
				this.$store.dispatch('simulator/loadNextGameState');
				this.close();
			},
			toggleShowDealRecording () {
				if (this.getDealRecording) {
					this.dealRecordingIsVisible = !this.dealRecordingIsVisible;
				}
			},
			handleBackToLesson () {
				this.close();
				this.$emit('backToLesson');
			},
			close () {
				this.$store.commit('simulator/setDealResultsModalIsVisible', false);
			},
			async fetchDealScores () {
				if (this.getIsStudyMode) {
					const response = await api.deals.getScores({
						dealIds: this.getSessionGameIds,
						lessonId: this.getLessonId
					});
					if (response) {
						this.scores = response;
					}
				} else if (
					this.getIsPreviewMode &&
					this.getScoreBoard
				) {
					this.scores = [
						{
							scoreBoard: this.getScoreBoard
						}
					];
				}
			},
			async fetchDealSummaryMessages () {
				if (this.getDealId) {
					const response = await api.deals.getDealResultMessages({
						dealId: this.getDealId
					});
					if (response) {
						this.messages = response;
					}
				}
			},
			async fetchDealMedia () {
				if (this.getDealId) {
					const response = await api.deals.getDealMedia({
						dealId: this.getDealId
					});
					if (response) {
						this.media = response;
					}
				}
			}
		}
	};

</script>

<style lang="scss" scoped>

	[data-component='deal-results-modal'] {
		width: 100%;
		height: 100%;

		[data-element='table-content'] {
			display: flex;

			[data-element='deal-diagrams'] {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				width: 100%;
				transform: scale(0.8);

				[data-component='deal-matrix'] {
					margin:0 0 rem(24);
				}

				[data-component='bidding-table'] {}
			}

			[data-element='deal-results-table'] {
				::v-deep [data-element='summary-message'] {
					padding-left: rem(16);
					font-size: rem(18);
					line-height: 1;
					margin-bottom: rem(12);
					font-weight:700;
					[data-element='suit-symbol'] {
						font-size: rem(16);
						font-weight: normal;
					}
					@include rich-text-suits;
				}

				::v-deep [data-component='dynamic-table'] {
					[data-element='table-column-index'] {
						width: 100px;
					}

					[data-element='table-column-contract'] {
						display: inline-flex;
						align-items: center;
						margin: 0;

						[data-element='contract-value'] {
							font-weight: 700;
						}

						[data-component='suit-symbol'] {
							margin-left: 0.1em;

							[data-component='icon'] {
								width: auto;
								height: rem(12);
							}
						}
					}

					[data-element='table-column-declarer'],
					[data-element='table-column-ourPoints'],
					[data-element='table-column-theirPoints'] {
						font-weight: 700;
					}

					[data-element='table-column-theirPoints'] {
						text-align: left;
					}
				}
			}
		}

		[data-component='actions'] {
			padding-bottom: 0;
		}
	}

</style>
