<template>
	<div data-component="game-info">
		<p
			v-if="getDealTitle"
			data-element="deal-title"
		>
			{{ getDealTitle }}
		</p>
		<p
			v-if="getVulnerabilityString"
			data-element="vulnerability"
		>
			{{ getVulnerabilityString }}
		</p>
		<div
			data-element="multiple-info"
			v-if="getHasMultipleDeals"
		>
			<p data-element="deal-number">
				{{ getDealNumberString }}
			</p>
			<div data-element="pagination">
				<icon
					icon="arrow-left"
					:colour="getPreviousIconColour"
					@click.native="loadPreviousGameState"
				/>
				<icon
					icon="arrow-right"
					:colour="getNextIconColour"
					@click.native="loadNextGameState"
				/>
			</div>
		</div>
	</div>
</template>

<script>

	import Icon from '@/components/ui/Icon';

	export default {
		components: {
			Icon
		},
		computed: {
			getDealTitle () {
				return this.$store.getters['simulator/getDealTitle'];
			},
			getVulnerabilityString () {
				return this.$store.getters['simulator/getVulnerabilityString'];
			},
			getGameStatesLength () {
				return this.$store.getters['simulator/getGameStatesLength'];
			},
			getCurrentGameStateIndex () {
				return this.$store.getters['simulator/getCurrentGameStateIndex'];
			},
			getHasMultipleDeals () {
				if (!this.getGameStatesLength ||
						this.getGameStatesLength === 1) {
					return false;
				}
				return true;
			},
			getDealNumberString () {
				if (!this.getHasMultipleDeals) {
					return false;
				}
				const length          = this.getGameStatesLength;
				const gameStateNumber = this.getCurrentGameStateIndex + 1;
				return `Deal ${gameStateNumber}/${length}`;
			},
			getPreviousIconColour () {
				if (this.$store.getters['simulator/getPreviousGameState']) {
					return 'blue';
				}
				return 'blue-lighter-2';
			},
			getNextIconColour () {
				if (this.$store.getters['simulator/getNextGameState']) {
					return 'blue';
				}
				return 'blue-lighter-2';
			}
		},
		methods: {
			loadPreviousGameState () {
				return this.$store.dispatch('simulator/loadPreviousGameState');
			},
			loadNextGameState () {
				return this.$store.dispatch('simulator/loadNextGameState');
			}
		}
	};

</script>

<style lang="scss" scoped>

	[data-component='game-info'] {
		user-select:none;
		[data-element='deal-title'] {
			margin:0 0 rem(7);
			text-align:right;
			font-size:rem(20);
			font-weight:700;
			line-height:1;
			color:$c-brand-blue;
		}
		[data-element='vulnerability'] {
			margin:0 0 rem(22);
			text-align:right;
			font-size:rem(18);
			line-height:1;
			color:$c-brand-blue;
		}
		[data-element='multiple-info'] {
			display:flex;
			align-items:center;
			margin:0 0 rem(2);
			[data-element='deal-number'] {
				margin-right:rem(8);
				font-size:rem(18);
				line-height:1;
				color:$c-brand-blue;
			}
			[data-element='pagination'] {
				display:flex;
				[data-component='icon'] {
					width:rem(28);
					height:auto;
					cursor:pointer;
				}
			}
		}
	}

</style>
