<template>
	<div data-component="dummy-columns">
		<div
			v-for="(suit, key) in cardsInSuits"
			:key="key"
			data-element="suit"
		>
			<card
				v-for="(card, key) in suit"
				:key="key"
				:card="card"
				:index="key"
				:isInDummy="true"
				:isInactive="getIsInactive"
			/>
		</div>
	</div>
</template>

<script>

	import Card  from '@/components/simulator/ui/Card';
	import dummyHand from '@/mixins/dummyHand';

	export default {
		components: {
			Card
		},
		mixins: [dummyHand],
		props: {
			cardsInSuits: {
				type: Array,
				default: () => []
			}
		}
	};

</script>

<style lang="scss" scoped>

	[data-component='dummy-columns'] {
		display:flex;
		justify-content:flex-end;
		width:100%;
		[data-element='suit'] {
			flex-grow:0;
			flex-shrink:0;
			width:$card-width;
			margin-left:pxToVw(8);
			&:first-child {
				margin-left:0;
			}
			[data-component='card'] {
				width:100%;
				margin-top:$card-vertical-overlap;
				cursor:pointer;
				&:first-child {
					margin-top: 0;
				}
			}
		}
	}

</style>
