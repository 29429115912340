<template>
	<div data-component="bid-history">
		<contract-and-declarer
			:isPartOfSimulator="true"
			size="lg"
			@click.native="toggleBidHistoryIsVisible"
		/>
		<div
			v-if="getBidHistoryIsVisible"
			data-element="table"
		>
			<div data-element="positions">
				<div
					v-for="(position, key) in ['W', 'N', 'E', 'S']"
					:key="key"
					data-element="position"
				>
					{{ position }}
				</div>
			</div>
			<div
				data-element="bids"
				ref="bids"
			>
				<bid-history-item
					v-for="(bid, key) of getBidHistory"
					:bid="bid"
					:key="key"
					:index="key"
				/>
			</div>
		</div>
	</div>
</template>

<script>

	import ContractAndDeclarer  from '@/components/diagrams/ContractAndDeclarer';
	import BidHistoryItem       from '@/components/simulator/bidding/BidHistoryItem';

	export default {
		name: 'BidHistory',
		components: {
			BidHistoryItem,
			ContractAndDeclarer
		},
		computed: {
			getBidHistoryIsVisible () {
				return this.$store.getters['simulator/getBidHistoryIsVisible'];
			},
			getBidHistory () {
				return this.$store.getters['simulator/getBidHistory'];
			}
		},
		watch: {
			getBidHistory () {
				this.scrollToBottom();
			}
		},
		methods: {
			toggleBidHistoryIsVisible () {
				if (!this.$store.getters['simulator/getAuctionIsComplete']) {
					return false;
				}
				if (this.$store.getters['simulator/getBidHistoryIsVisible']) {
					return this.$store.commit('simulator/setBidHistoryIsNotVisible');
				}
				this.$store.commit('simulator/setBidHistoryIsVisible');
				this.scrollToBottom();
			},
			scrollToBottom () {
				this.$nextTick(() => {
					if (!this.$refs.bids) {
						return false;
					}
					this.$refs.bids.scrollTop = this.$refs.bids.scrollHeight;
				});
			}
		}
	};

</script>

<style lang="scss" scoped>

	[data-component='bid-history'] {
		display:flex;
		flex-direction:column;
		align-items:flex-start;
		[data-component='contract-and-declarer'] {
			margin:0 0 rem(10) 0;
			color:$c-brand-blue;
			background-color:$c-white;
			padding:rem(6) rem(10);
			border-radius:4px;
			cursor:pointer;
			user-select:none;
		}
		[data-element='table'] {
			width:rem(240);
			user-select:none;
			[data-element='positions'] {
				display:flex;
				margin-bottom:2px;
				background-color:$c-white;
				[data-element='position'] {
					display:flex;
					align-items:center;
					justify-content:center;
					width:25%;
					padding:rem(8) 0;
					font-size:rem(16);
					line-height:1;
					font-weight:700;
					text-transform:uppercase;
					color:$c-brand-grey;
					background-color:white;
				}
			}
			[data-element='bids'] {
				min-height:rem(32);
				max-height:rem(32*5);
				overflow-y:auto;
				background-color:$c-white;
				[data-component='bid-history-item'] {
					&:first-child {
						&[data-player='W'] {
						}
						&[data-player='N'] {
							margin-left:25%;
						}
						&[data-player='E'] {
							margin-left:50%;
						}
						&[data-player='S'] {
							margin-left:75%;
						}
					}
				}
				display:flex;
				flex-wrap:wrap;
				width:100%;
				background-color:white;
			}
		}
	}

</style>
