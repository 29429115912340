<template>
	<div data-component="parameters">
		<simulator-notification
			v-if="getBidExplanationIsVisible"
			@close="hideBidExplanation"
			heading="Explanation"
			:content="getActiveBidExplanation"
		/>
		<simulator-notification
			v-if="getBidHintIsVisible"
			@close="hideBidHint"
			heading="Hint"
			:content="getActiveBidHint"
		/>
		<simulator-notification
			v-if="getDeclarerHintIsVisible"
			@close="hideDeclarerHint"
			heading="Hint"
			:content="getActiveDeclarerHint"
		/>
		<change-player-position-notification />
		<claiming-tricks-modal />
		<claiming-tricks-button />
		<deal-results-modal @backToLesson="handleBackToLesson" />
	</div>
</template>
<script>

	import SimulatorNotification from '@/components/simulator/ui/SimulatorNotification';
	import ChangePlayerPositionNotification from '@/components/simulator/parameters/ChangePlayerPositionNotification';
	import ClaimingTricksModal from '@/components/simulator/parameters/ClaimingTricksModal';
	import ClaimingTricksButton from '@/components/simulator/parameters/ClaimingTricksButton';
	import DealResultsModal from '@/components/simulator/parameters/DealResultsModal';

	export default {
		components: {
			SimulatorNotification,
			ChangePlayerPositionNotification,
			ClaimingTricksModal,
			ClaimingTricksButton,
			DealResultsModal
		},
		data () {
			return {};
		},
		computed: {
			getBidExplanationIsVisible () {
				return this.$store.getters['simulatorParameters/getBidExplanationIsVisible'];
			},
			getActiveBidExplanation () {
				return this.$store.getters['simulatorParameters/getActiveBidExplanation'];
			},
			getBidHintIsVisible () {
				return this.$store.getters['simulatorParameters/getBidHintIsVisible'];
			},
			getActiveBidHint () {
				return this.$store.getters['simulatorParameters/getActiveBidHint'];
			},
			getDeclarerHintIsVisible () {
				return this.$store.getters['simulatorParameters/getDeclarerHintIsVisible'];
			},
			getActiveDeclarerHint () {
				return this.$store.getters['simulatorParameters/getActiveDeclarerHint'];
			}
		},
		methods: {
			hideBidExplanation () {
				this.$store.dispatch('simulatorParameters/hideBidExplanation');
			},
			hideBidHint () {
				this.$store.dispatch('simulatorParameters/hideBidHint');
			},
			hideDeclarerHint () {
				this.$store.dispatch('simulatorParameters/hideDeclarerHint');
			},
			handleBackToLesson () {
				this.$emit('backToLesson');
			}
		}
	};

</script>

<style lang="scss" scoped>

	[data-component='parameters'] {
		width: 100%;
		height: 100%;
	}

</style>
