<template>
	<div
		v-if="getIsStudyMode"
		data-component="study-controls"
	>
		<div data-component="study-controls">
			<div
				v-if="getBiddingControlsIsVisible"
				data-element="bidding-controls"
			>
				<btn
					v-if="getIsStudyModeBiddingNotStarted"
					text="Start bidding"
					size="xl"
					@click.native="startStudyModeBidding"
				/>
				<div
					v-else
					data-element="hint-controls"
				>
					<btn
						v-if="getNextCorrectBidHint"
						text="Show hint"
						size="sm"
						btnId="showHint"
						:isDisabled="getIsShowHintDisabled"
						@click.native="showHint"
					/>
					<btn
						v-if="getIsBidAttemptFailed"
						text="Show correct bid"
						size="sm"
						@click.native="showCorrectBid"
					/>
				</div>
			</div>
			<template v-if="getTricksControlsIsVisible">
				<btn
					v-if="getIsShowDeclarerHintVisible"
					text="Show hint"
					size="sm"
					@click.native="showDeclarerHint"
				/>
			</template>
		</div>
	</div>
</template>

<script>

	import Btn from '@/components/ui/Btn';

	export default {
		name: 'StudyControls',
		components: {
			Btn
		},
		props: {},
		computed: {
			getIsStudyMode () {
				return this.$store.getters['simulator/getIsStudyMode'];
			},
			getAuctionIsComplete () {
				return this.$store.getters['simulator/getAuctionIsComplete'];
			},
			getBiddingControlsIsVisible () {
				return !this.getAuctionIsComplete;
			},
			getIsStudyModeBiddingNotStarted () {
				return this.$store.getters['simulator/getIsStudyModeBiddingNotStarted'];
			},
			getPlayerPosition () {
				return this.$store.getters['simulator/getPlayerPosition'];
			},
			getIsCurrentPlayer () {
				return this.$store.getters['simulator/getPlayerIsCurrentByPosition']({
					position: this.getPlayerPosition
				});
			},
			getNextCorrectBidHint () {
				return this.$store.getters['simulator/getNextCorrectBidHint'];
			},
			getIsShowHintDisabled () {
				return this.getIsCurrentPlayer ? undefined : 'showHint';
			},
			getIsBidAttemptFailed () {
				return this.$store.getters['simulator/getPlayerFailedAttemptToBid'];
			},
			getGameIsComplete () {
				return this.$store.getters['simulator/getGameIsComplete'];
			},
			getIsNextDealExist () {
				return !!this.$store.getters['simulator/getNextGameState'];
			},
			getIsDealCompleted () {
				return this.getAuctionIsComplete && this.getGameIsComplete && this.getIsNextDealExist;
			},
			getTricksControlsIsVisible () {
				return !this.getIsDealCompleted;
			},
			getStudyModeTricksInProgress () {
				return this.$store.getters['simulator/getStudyModeTricksInProgress'];
			},
			getDeclarerHint () {
				return this.$store.getters['simulator/getDeclarerHint'];
			},
			getOpeningLeadHasBeenPlayed () {
				return this.$store.getters['simulator/getOpeningLeadHasBeenPlayed'];
			},
			getPlayerHasWonTwoTricks () {
				const tricksWon = this.$store.getters['simulator/getPlayerHasWonTricksCount'];
				return tricksWon >= 2;
			},
			getIsShowDeclarerHintVisible () {
				return [
					this.getStudyModeTricksInProgress,
					this.getDeclarerHint,
					this.getOpeningLeadHasBeenPlayed,
					!this.getPlayerHasWonTwoTricks
				].every(r => !!r);
			}
		},
		methods: {
			startStudyModeBidding () {
				this.$store.dispatch('simulator/startStudyModeBidding');
			},
			showHint () {
				this.$store.dispatch('simulator/showHint');
			},
			showCorrectBid () {
				this.$store.dispatch('simulator/highlightCorrectBid');
			},
			showDeclarerHint () {
				this.$store.dispatch('simulator/showDeclarerHint');
			}
		}
	};
</script>

<style lang="scss" scoped>

	[data-component='study-controls'] {
		display: flex;
		flex-direction: column;

		[data-element='bidding-controls'] {
			display: flex;
			justify-content: center;
			margin-bottom: rem(16);

			[data-element='hint-controls'] {
				display: flex;
				justify-content: center;
				margin-top: rem(16);

				[data-component='btn'] {
					margin: 0 rem(6);
				}
			}
		}
	}

</style>
