export default {
	computed: {
		getAuctionIsComplete () {
			return this.$store.getters['simulator/getAuctionIsComplete'];
		},
		getIsStudyMode () {
			return this.$store.getters['simulator/getIsStudyMode'];
		},
		getCurrentPlayer () {
			return this.$store.getters['simulator/getCurrentPlayer'];
		},
		getIsDummy () {
			return this.$store.getters['simulator/getIsDummyByPosition']({
				position: this.getCurrentPlayer
			});
		},
		getIsInactive () {
			if (!this.getIsStudyMode) {
				return false;
			}
			if (this.getAuctionIsComplete) {
				return !this.getIsDummy;
			}
			return true;
		}
	}
};
