<template>
	<div
		data-component="player-bids"
		:data-position="position"
		:data-table-position="tablePosition"
		v-if="getIsVisible"
	>
		<div
			data-element="player-bid"
			v-for="(bid, key) in getBids"
			:key="key"
			:data-current="getIsCurrentBid(bid)"
			:data-dimmed="getIsBeforeCurrentBid(bid)"
			:data-players-current="getIsPlayersCurrentBid(key)"
		>
			<bid
				:bid="bid"
				:showBackgroundColour="true"
			/>
		</div>
	</div>
</template>

<script>

	import Bid from '@/components/diagrams/Bid';

	export default {
		components: {
			Bid
		},
		props: {
			position: {
				type: String,
				required: true
			},
			tablePosition: {
				type: String,
				required: true
			}
		},
		computed: {
			getBids () {
				return this.$store.getters['simulator/getRecentBidsByPosition']({
					position: this.position
				});
			},
			getIsVisible () {
				return (this.getAuctionIsVisible || this.getStudyAuctionIsVisible) && this.getPlayerHasBids;
			},
			getPlayerHasBids () {
				if (!this.getBids?.length) {
					return false;
				}
				return true;
			},
			getAuctionIsVisible () {
				return this.$store.getters['simulator/getAuctionIsVisible'];
			},
			getStudyAuctionIsVisible () {
				return this.$store.getters['simulator/getStudyAuctionIsVisible'];
			}
		},
		methods: {
			getIsPlayersCurrentBid (index) {
				return index === 0;
			},
			getIsCurrentBid (bid) {
				return this.$store.getters['simulator/getBidIsCurrentBid']({
					bid
				});
			},
			getIsBeforeCurrentBid (bid) {
				return this.$store.getters['simulator/getBidIsBeforeCurrentBid']({
					bid
				});
			}
		}
	};

</script>

<style lang="scss" scoped>

	[data-component='player-bids'] {
		display:flex;
		flex-direction:row;
		justify-content:center;
		align-items:center;
		user-select:none;
		[data-element='player-bid'] {
			display:flex;
			align-items:center;
			justify-content:center;
			margin:pxToVw(2);
			::v-deep [data-component='bid'] {
				width:rem(40); height:rem(40);
				border-radius:4px;
				[data-element='value'] {
					font-size:rem(22);
					line-height:1;
					font-weight:700;
					color:$c-brand-black;
				}
				[data-component='suit-symbol'] {
					margin-left:0.1em;
					[data-component='icon'] {
						width:auto; height:rem(14);
					}
				}
			}
			&[data-dimmed] {
				opacity:0.4;
			}
			&[data-players-current] {
				[data-component='bid'] {
					width:rem(52); height:rem(52);
				}
			}
			&[data-current] {
				border-radius:8px;
				padding:rem(8);
				background-color:rgba(255,255,255,0.55);
			}
		}
		&[data-table-position='top'] {
			margin-top:pxToVw(3);
		}
		&[data-table-position='right'] {
			margin-right:pxToVw(10);
		}
		&[data-table-position='bottom'] {
			margin-bottom:pxToVw(10);
		}
		&[data-table-position='left'] {
			margin-left:pxToVw(10);
		}
		&[data-table-position='top'],
		&[data-table-position='bottom'] {
			[data-element='player-bid'] {
				margin:0 pxToVw(2);
			}
		}
		&[data-table-position='right'],
		&[data-table-position='left'] {
			flex-direction:column;
			[data-element='player-bid'] {
				margin:pxToVw(2) 0;
			}
		}
		// &[data-table-position='right'] {
		// 	margin-right:pxToVw(8);
		// 	outline:1px solid red;
		// }
	}

</style>
