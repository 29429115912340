<template>
	<div
		data-component="bid-history-item"
		:data-player="bid.player"
		:data-contract="getBidIsContract"
		:style="itemStyle"
		@mouseover="handleBidClick($event)"
	>
		<bid
			:bid="bid"
			:showBackgroundColour="false"
		/>
	</div>
</template>

<script>

	import Bid from '@/components/diagrams/Bid';

	export default {
		components: {
			Bid
		},
		props: {
			bid: {
				type: Object,
				required: true
			},
			index: {
				type: Number,
				default: undefined
			}
		},
		computed: {
			getBidIsContract () {
				return this.$store.getters['simulator/getBidIsContract']({
					bid: this.bid
				});
			},
			getBidExplanationByIndex () {
				return this.$store.getters['simulatorParameters/getBidExplanationByIndex'](this.index);
			},
			getBidExplanationIsVisible () {
				return this.$store.getters['simulator/getBidExplanationIsVisible'] && this.getBidExplanationByIndex;
			},
			itemStyle () {
				if (this.getBidExplanationIsVisible) {
					return {
						cursor: 'pointer'
					};
				}
				return {};
			}
		},
		methods: {
			handleBidClick (event) {
				if (this.getBidExplanationIsVisible) {
					event.stopPropagation();
					this.$store.dispatch('simulatorParameters/showBidExplanationByIndex', this.index);
				}
			}
		}
	};

</script>

<style lang="scss" scoped>

	[data-component='bid-history-item'] {
		display: flex;
		align-items: center;
		width: 25%;
		height: rem(32);

		::v-deep [data-component='bid'] {
			flex-grow: 1;
			margin: 0;
			[data-element='value'] {
				margin-right: 0.1em;
				font-size: rem(18);
				line-height: 1;
				font-weight: 700;
				text-transform: uppercase;
				color: $c-brand-black;
			}
			[data-component='suit-symbol'] {
				[data-component='icon'] {
					width: auto;
					height: rem(14);
				}
			}
		}

		[data-element='explanation-icon'] {
			width: rem(22);;
			height: rem(22);
		}
	}

</style>
