<template>
	<div
		v-if="getAuctionIsComplete"
		data-component="score"
	>
		<p
			data-element="partnership"
			v-for="(partnership, key) in partnerships"
			:key="key"
			:data-horizontal="getIsHorizontal(partnership)"
			:data-vertical="getIsVertical(partnership)"
		>
			<span data-element="label">
				{{ getPartnershipString(partnership) }}
			</span>
			<span data-element="tricks">
				{{ getScore(partnership) }}
			</span>
		</p>
	</div>
</template>

<script>

	export default {
		name: 'TrickTally',
		data: () => ({
			partnerships: [
				'N/S',
				'E/W'
			]
		}),
		computed: {
			getAuctionIsComplete () {
				return this.$store.getters['simulator/getAuctionIsComplete'];
			}
		},
		methods: {
			getScore (partnership) {
				return this.$store.getters['simulator/getScoreByPartnership']({
					partnership: partnership.replace('/', '')
				});
			},
			getIsHorizontal (partnership) {
				return this.$store.getters['simulator/getPartnershipIsHorizontalByPartnership']({
					partnership
				});
			},
			getIsVertical (partnership) {
				return this.$store.getters['simulator/getPartnershipIsVerticalByPartnership']({
					partnership
				});
			},
			getPartnershipString (partnership) {
				return partnership.replace('/', '');
			}
		}
	};

</script>

<style lang="scss" scoped>

	[data-component='score'] {
		position:relative;
		display:flex;
		text-align:right;
		user-select:none;
		width:rem(48*2);
		height:rem(64*2);
		[data-element='partnership'] {
			position:absolute;
			display:flex;
			flex-direction:column;
			align-items:center;
			justify-content:center;
			width:rem(48);
			height:rem(64);
			margin:0;
			line-height:1;
			border-radius:8px;
			border:3px solid $c-brand-blue;
			&[data-vertical] {
				bottom:0;
				left:0;
			}
			&[data-horizontal] {
				top:0;
				right:0;
			}
			[data-element='label'] {
				margin:rem(9) 0 0;
				font-size:rem(20);
				line-height:1;
				font-weight:700;
				text-transform: uppercase;
				color:$c-brand-blue;
			}
			[data-element='tricks'] {
				display:block;
				font-size:rem(28);
				line-height:1;
				font-weight:700;
				margin-bottom:8px;
				color:$c-brand-blue;
			}
		}
	}

</style>
