<template>
	<portal
		to="stackedOnTopModal"
		v-if="getIsVisible"
	>
		<modal-inner
			@close="handleClose"
			size="sm"
			heading="Player position"
		>
			<div data-component="change-player-position-notification">
				<p>
					The bidding resulted in you being the dummy, so we set your seat to be the declarer instead.
				</p>
				<div data-element="modal-actions">
					<checkbox-field
						name="Don’t show this again"
						id="show#ChangePlayerPositionNotification"
						v-model="getShowChangePlayerPositionNotification"
					/>
					<btn
						@click.native="handleClose"
						text="Close"
					/>
				</div>
			</div>
		</modal-inner>
	</portal>
</template>
<script>

	import ModalInner from '@/components/ui/modal/ModalInner';
	import CheckboxField from '@/components/forms/CheckboxField';
	import Btn from '@/components/ui/Btn';
	import { DONT_SHOW_CHANGE_PLAYER_POSITION_NOTIFICATION_KEY } from '@/consts';
	import { get, set } from 'local-storage';

	export default {
		components: {
			ModalInner,
			CheckboxField,
			Btn
		},
		data () {
			return {};
		},
		computed: {
			getIsVisible () {
				return this.$store.getters['simulator/getChangePlayerPositionNotificationIsVisible'];
			},
			getShowChangePlayerPositionNotification: {
				get () {
					return !!get(DONT_SHOW_CHANGE_PLAYER_POSITION_NOTIFICATION_KEY);
				},
				set (value) {
					set(DONT_SHOW_CHANGE_PLAYER_POSITION_NOTIFICATION_KEY, value);
				}
			}
		},
		methods: {
			handleClose () {
				this.$store.dispatch('simulator/closeChangePlayerPositionNotification');
			}
		}
	};

</script>

<style lang="scss" scoped>

	[data-component='change-player-position-notification'] {
		width: 100%;
		height: 100%;
		[data-element='modal-actions'] {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding-top: rem(20);
		}
	}

</style>
