<template>
	<div data-component="dummy-rows">
		<div
			v-for="(suit, key) in cardsInSuits"
			:key="key"
			data-element="suit"
		>
			<card
				v-for="(card, key) in suit"
				:key="key"
				:card="card"
				:index="key"
				:isInDummy="true"
				:isInactive="getIsInactive"
			/>
		</div>
	</div>
</template>

<script>

	import Card  from '@/components/simulator/ui/Card';
	import dummyHand from '@/mixins/dummyHand';

	export default {
		components: {
			Card
		},
		mixins: [dummyHand],
		props: {
			cardsInSuits: {
				type: Array,
				default: () => []
			}
		}
	};

</script>

<style lang="scss" scoped>

	[data-component='dummy-rows'] {
		display:flex;
		flex-direction:column;
		width:100%;
		[data-element='suit'] {
			display:flex;
			height:$card-height;
			margin-top:$card-vertical-overlap;
			&:first-child {
				margin-top:0;
			}
			[data-component='card'] {
				width:$card-width;
				height:$card-height;
				margin-left:$card-horizontal-overlap;
				cursor:pointer;
				&:first-child {
					margin-left: 0;
				}
			}
		}
	}

</style>
