<template>
	<div
		data-component="simulator-notification"
		v-click-outside="close"
	>
		<div data-element="simulator-notification-header">
			<h3 v-if="heading">
				{{ heading }}
			</h3>
			<icon
				data-element="close"
				icon="cross"
				colour="blue"
				@click.native="close"
			/>
		</div>
		<div
			data-element="simulator-notification-content"
			v-html="content"
		/>
	</div>
</template>
<script>

	import Icon from '@/components/ui/Icon';

	export default {
		components: {
			Icon
		},
		props: {
			heading: {
				type: String,
				default: undefined
			},
			content: {
				type: [String, undefined],
				default: undefined
			}
		},
		methods: {
			close () {
				this.$emit('close');
			}
		}
	};

</script>

<style lang="scss" scoped>

	[data-component='simulator-notification'] {
		position: fixed;
		z-index: 1;
		top: 50%;
		left: 50%;
		transform: translate(-50%, rem(28 * -8)); // Above the auction
		width: 100%;
		max-width: 360px;
		background: $c-white;
		border-radius: 8px;
		box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.5);

		[data-element='simulator-notification-header'] {
			display: flex;
			padding: rem(10) rem(10) rem(10) rem(18);
			border-bottom: 1px solid $c-brand-grey-lighter-4;

			h3 {
				margin: auto rem(22) auto 0;
				font-weight: 700;
				font-size: rem(18);
				color: $c-brand-blue;
			}

			[data-element='close'] {
				margin: auto 0 auto auto;
				cursor: pointer;
				user-select: none;
				height: rem(26);
				width: auto;
			}
		}

		::v-deep [data-element='simulator-notification-content'] {
			padding: rem(18);
			@include rich-text-content;
			@include rich-text-suits;
		}
	}

</style>
