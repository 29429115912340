<template>
	<div
		data-component="hand"
		v-if="getCards"
	>
		<div data-element="cards">
			<card
				v-for="(card, index) in getCards"
				:key="index"
				:card="card"
				:index="index"
				:isInactive="getIsInactive"
			/>
		</div>
	</div>
</template>

<script>

	import Card  from '@/components/simulator/ui/Card';

	export default {
		components: {
			Card
		},
		props: {
			position: {
				type: String,
				required: true,
				validator: (value) => {
					return [
						'N',
						'E',
						'S',
						'W'
					].includes(value);
				}
			},
			isCurrentPlayer: {
				type: Boolean,
				default: false
			}
		},
		computed: {
			getCards () {
				return this.$store.getters['simulator/getUnplayedCardsByPosition']({
					position: this.position
				});
			},
			getAuctionIsComplete () {
				return this.$store.getters['simulator/getAuctionIsComplete'];
			},
			getGameIsComplete () {
				return this.$store.getters['simulator/getGameIsComplete'];
			},
			getIsStudyMode () {
				return this.$store.getters['simulator/getIsStudyMode'];
			},
			getPlayerPosition () {
				return this.$store.getters['simulator/getPlayerPosition'];
			},
			getIsInactive () {
				if (this.getIsStudyMode) {
					if (
						this.getAuctionIsComplete &&
						!this.getGameIsComplete &&
						this.getPlayerPosition === this.position
					) {
						return !this.isCurrentPlayer;
					}
					return true;
				}
				return false;
			}
		}
	};

</script>

<style lang="scss" scoped>

	[data-component='hand'] {
		display:flex;
		justify-content:center;
		width:100%;
		[data-element='cards'] {
			display:flex;
			[data-component='card'] {
				flex-grow:1;
				flex-shrink:0;
				width:$card-width;
				margin-left:$card-horizontal-overlap;
				cursor:pointer;
				&:first-child {
					margin-left:0;
				}
			}
		}
	}

</style>
