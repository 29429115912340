<template>
	<div
		v-show="getIsVisible"
		data-component="claiming-tricks-button"
		:data-mode="getMode"
	>
		<btn
			text="Claim"
			btnId="claim"
			:isDisabled="getIsDisabled"
			@click.native="handleOpenModal"
		/>
	</div>
</template>
<script>

	import Btn from '@/components/ui/Btn';
	import { partnerships } from '@/consts';

	export default {
		components: {
			Btn
		},
		data () {
			return {};
		},
		computed: {
			getMode () {
				return this.$store.getters['simulator/getGameMode'];
			},
			getAuctionIsComplete () {
				return this.$store.getters['simulator/getAuctionIsComplete'];
			},
			getOpeningLeadHasBeenPlayed () {
				return this.$store.getters['simulator/getOpeningLeadHasBeenPlayed'];
			},
			getGameIsComplete () {
				return this.$store.getters['simulator/getGameIsComplete'];
			},
			getIsVisible () {
				return this.getAuctionIsComplete && this.getOpeningLeadHasBeenPlayed && !this.getGameIsComplete;
			},
			getClaimParameters () {
				return this.$store.getters['simulator/getClaimParameters'];
			},
			getPlayerPosition () {
				return this.$store.getters['simulator/getPlayerPosition'];
			},
			getOpponentTeam () {
				return partnerships.find((partnership) => {
					return !partnership.includes(this.getPlayerPosition);
				});
			},
			getTricks () {
				return this.$store.getters['simulator/getTricks'] || [];
			},
			getPlayedTricks () {
				return this.getTricks.filter(trick => !!trick.winningPair);
			},
			getPlayerHasWonTricksCount () {
				return this.$store.getters['simulator/getPlayerHasWonTricksCount'];
			},
			getOpponentTeamCards () {
				return this.getOpponentTeam
					.map(position => {
						return this.$store.getters['simulator/getUnplayedCardsByPosition']({ position }) || [];
					})
					.flat()
					.map(card => card.name);
			},
			getOpponentTeamCardsSuits () {
				return [
					...new Set(this.getOpponentTeamCards.map(card => {
						const parts = card.split('');
						return parts[1];
					}))
				];
			},
			getIsCanClaim () {
				const afterTrickIsPlayedParameter = this.getParameterByKey('after_trick_is_played');
				const afterTricksWonParameter = this.getParameterByKey('after_tricks_won');
				const opponentNoCardsOfSuitParameter = this.getParameterByKey('opponent_no_cards_of_suit');
				const conditions = [];

				if (afterTrickIsPlayedParameter !== false) {
					conditions.push(this.getPlayedTricks.length >= afterTrickIsPlayedParameter);
				}
				if (afterTricksWonParameter !== false) {
					conditions.push(this.getPlayerHasWonTricksCount >= afterTricksWonParameter);
				}
				if (opponentNoCardsOfSuitParameter !== false) {
					conditions.push(this.getOpponentTeamCardsSuits.every(suit => !opponentNoCardsOfSuitParameter.includes(suit)));
				}
				if (conditions.length) {
					return conditions.every(condition => !!condition);
				}

				return true;
			},
			getIsDisabled () {
				return this.getIsCanClaim ? undefined : 'claim';
			}
		},
		methods: {
			handleOpenModal () {
				this.$store.commit('simulator/setClaimingTricksModalIsVisible', true);
			},
			getParameterByKey (key) {
				const parameter = this.getClaimParameters.find(item => item.key === key);
				if (parameter) {
					return parameter.value;
				}
				return false;
			}
		}
	};

</script>

<style lang="scss" scoped>

	[data-component='claiming-tricks-button'] {
		position: fixed;
		bottom: rem(36);
		left: rem(36);

		&[data-mode='preview'] {
			bottom: rem(48 * 3);
		}
	}

</style>
