<template>
	<div
		v-if="getHighlightingModeIsEnabled"
		data-component="highlighting-alert"
		@click="click"
	>
		<p>
			Highlighting mode is turned on. Click here to turn it off.
		</p>
	</div>
</template>

<script>

	export default {
		name: 'TrickTally',
		data: () => ({
			partnerships: [
				'N/S',
				'E/W'
			]
		}),
		computed: {
			getHighlightingModeIsEnabled () {
				return this.$store.getters['simulator/getHighlightingModeIsEnabled'];
			}
		},
		methods: {
			click () {
				this.$store.dispatch('simulator/setHighlightingModeIsDisabled');
			}
		}
	};

</script>

<style lang="scss" scoped>

	[data-component='highlighting-alert'] {
		display:flex;
		align-items:center;
		justify-content:center;
		padding:rem(3) 0;
		text-align:center;
		background-color:$c-brand-green-lighter-2;
		user-select:none;
		cursor:pointer;
		p {
			font-size:rem(11);
			line-height:1;
			font-weight:700;
		}
	}

</style>
