<template>
	<div
		v-if="getCards"
		data-component="trick"
	>
		<card
			v-for="(card, index) in getCards"
			:key="index"
			:card="card"
			:index="index"
			:data-winner="getCardIsWinningCardInCurrentTrick(card)"
		/>
	</div>
</template>

<script>
	import Card  from '@/components/simulator/ui/Card';
	export default {
		name: 'Trick',
		components: {
			Card
		},
		data: () => ({}),
		computed: {
			getCards () {
				return this.$store.getters['simulator/getCardsInCurrentTrick'];
			}
		},
		methods: {
			getCardIsWinningCardInCurrentTrick (card) {
				return this.$store.getters['simulator/getCardIsWinningCardInCurrentTrick']({
					card
				});
			}
		}
	};
</script>

<style lang="scss" scoped>

	[data-component='trick'] {
		width:$card-width;
		height:$card-height;
		user-select:none;
		[data-component='card'] {
			position: absolute;
			width:$card-width;
			height:$card-height;
			&[data-index='0'] {
				z-index:0;
			}
			&[data-index='1'] {
				z-index:1;
			}
			&[data-index='2'] {
				z-index:2;
			}
			&[data-index='3'] {
				z-index:3;
			}
			&[data-table-position='top'] {
				bottom:28%;
				right:5%;
			}
			&[data-table-position='bottom'] {
				top:28%;
				left:5%;
			}
			&[data-table-position='right'] {
				bottom:8%;
				left:30%;
			}
			&[data-table-position='left'] {
				top:8%;
				right:30%;
			}
			&[data-winner] {
				z-index:4;
				background-color:lighten($c-brand-green-lighter-2, 60%);
			}
		}
	}

</style>
