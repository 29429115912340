<template>
	<div
		data-component="hand-footer"
		:data-vulnerable="getIsVulnerable"
		:data-is-dummy="getIsDummyLayout"
		:data-dummy-layout="getDummyLayout"
	>
		<div data-element="position-container">
			<div
				data-element="position"
				v-if="getPositionString"
			>
				{{ getPositionString }}
			</div>
			<div
				data-element="dealer-label"
				v-if="getIsDealer"
			>
				Dealer
			</div>
		</div>
		<div
			data-element="role"
			v-for="(role, key) in getRoles"
			:key="key"
		>
			{{ role.text }}
		</div>
		<div data-element="buttons">
			<simulator-button
				v-if="getTurningButtonIsVisible"
				:icon="getFacingToggleIcon"
				size="xs"
				@click.native="toggleHandIsFaceDown"
				colour="blue"
			/>
			<simulator-button
				v-if="getHidingButtonIsVisible"
				:icon="getHideToggleIcon"
				size="xs"
				@click.native="toggleHandIsHidden"
				colour="blue"
			/>
		</div>
	</div>
</template>

<script>

	import SimulatorButton  from '@/components/simulator/ui/SimulatorButton';

	export default {
		components: {
			SimulatorButton
		},
		props: {
			position: {
				type: String,
				required: true
			}
		},
		computed: {
			getMode () {
				return this.$store.getters['simulator/getGameMode'];
			},
			getRoles () {
				return this.$store.getters['simulator/getRolesByPosition']({
					position: this.position
				});
			},
			getPositionString () {
				return this.position;
			},
			getIsDealer () {
				return this.$store.getters['simulator/getIsDealerByPosition']({
					position: this.position
				});
			},
			getIsVulnerable () {
				return this.$store.getters['simulator/getIsVulnerableByPosition']({
					position: this.position
				});
			},
			getTurningButtonIsVisible () {
				return this.$store.getters['simulator/getTurningButtonIsVisibleForMode'](this.getMode);
			},
			getIsFaceDown () {
				return this.$store.getters['simulator/getHandIsFaceDownByPosition']({
					position: this.position
				});
			},
			getHidingButtonIsVisible () {
				return this.$store.getters['simulator/getHidingButtonIsVisibleForMode'](this.getMode);
			},
			getIsHidden () {
				return this.$store.getters['simulator/getHandIsHiddenByPosition']({
					position: this.position
				});
			},
			getHideToggleIcon () {
				if (this.getIsHidden) {
					return 'hide';
				}
				return 'show';
			},
			getFacingToggleIcon () {
				if (this.getIsFaceDown) {
					return 'flip card';
				}
				return 'flip card';
			},
			getIsDummyLayout () {
				return this.$store.getters['simulator/getIsDummyLayoutByPosition']({
					position: this.position
				});
			},
			getDummyLayout () {
				return this.$store.getters['simulator/getDummyLayoutByPosition']({
					position: this.position
				});
			}
		},
		methods: {
			toggleHandIsHidden () {
				this.$store.dispatch('simulator/toggleHandIsHiddenByPosition', {
					position: this.position
				});
			},
			toggleHandIsFaceDown () {
				this.$store.dispatch('simulator/toggleHandIsFaceDownByPosition', {
					position: this.position
				});
			}
		}
	};

</script>

<style lang="scss" scoped>

	[data-component='hand-footer'] {
		position:relative;
		display:flex;
		align-items:center;
		margin-top:rem(4);
		border-radius:4px;
		width:$hand-footer-width;
		height:rem(28);
		padding:rem(4);
		background-color:$c-brand-green-lighter-1;
		&[data-dummy-layout='columns'] {
			width:100%;
		}
		&[data-vulnerable] {
			background-color:$c-brand-red-lighter-2;
		}
		[data-element='position-container'] {
			position:absolute;
			left:rem(12);
			display:flex;
			align-items:center;
			[data-element='position'] {
				margin-right:rem(12);
				font-size:rem(18);
				line-height:1;
				font-weight:700;
				color:$c-brand-blue;
			}
			[data-element='dealer-label'] {
				font-size:rem(12);
				line-height:1;
				font-weight:700;
				text-transform:uppercase;
				color:$c-brand-blue;
			}
		}
		[data-element='role'] {
			margin:auto;
			font-size:rem(14);
			line-height:1;
			font-weight:700;
			text-transform:uppercase;
			color:$c-brand-blue;
		}
		[data-element='buttons'] {
			position:absolute;
			top:0;
			right:rem(4);
			display:flex;
			[data-component='simulator-button'] {
				margin-left:rem(4);
				margin-right:0;
			}
		}
	}

</style>
