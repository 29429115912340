import { render, staticRenderFns } from "./DealResultsModal.vue?vue&type=template&id=6b93046f&scoped=true&"
import script from "./DealResultsModal.vue?vue&type=script&lang=js&"
export * from "./DealResultsModal.vue?vue&type=script&lang=js&"
import style0 from "./DealResultsModal.vue?vue&type=style&index=0&id=6b93046f&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b93046f",
  null
  
)

export default component.exports