<template>
	<div
		data-component="player"
		:data-position="position"
		:data-table-position="getTablePosition"
		v-if="getPlayersAreVisible"
	>
		<div
			data-element="hand-wrapper"
		>
			<component
				:is="getHandComponent"
				:position="position"
				:tablePosition="getTablePosition"
				:isCurrentPlayer="getIsCurrentPlayer"
			/>
			<hand-footer :position="position" />
			<div
				data-element="current-indicator"
				:data-current="getIsCurrentPlayer"
			/>
		</div>
		<player-bids
			:position="position"
			:tablePosition="getTablePosition"
		/>
	</div>
</template>

<script>

	import Hand        from '@/components/simulator/player/Hand';
	import Dummy       from '@/components/simulator/player/Dummy';
	import HandFooter  from '@/components/simulator/player/HandFooter';
	import PlayerBids  from '@/components/simulator/player/PlayerBids';

	export default {
		components: {
			Hand,
			Dummy,
			HandFooter,
			PlayerBids
		},
		props: {
			position: {
				type: String,
				required: true,
				validator: (value) => {
					return [
						'N',
						'E',
						'S',
						'W'
					].includes(value);
				}
			}
		},
		computed: {
			getTablePosition () {
				return this.$store.getters['simulator/getTablePositionByPosition']({
					position: this.position
				});
			},
			getPlayersAreVisible () {
				return this.$store.getters['simulator/getPlayersAreVisible'];
			},
			getIsCurrentPlayer () {
				return this.$store.getters['simulator/getPlayerIsCurrentByPosition']({
					position: this.position
				});
			},
			getIsDummyLayout () {
				return this.$store.getters['simulator/getIsDummyLayoutByPosition']({
					position: this.position
				});
			},
			getHandComponent () {
				if (this.getIsDummyLayout) {
					return 'Dummy';
				}
				return 'Hand';
			}
		}
	};

</script>

<style lang="scss" scoped>

	[data-component='player'] {
		position:relative;
		display:flex;
		justify-content:center;
		align-items:center;
		[data-element='hand-wrapper'] {
			[data-element='current-indicator'] {
				width:100%;
				height:pxToVw(4);
				background-color:transparent;
				margin-top:pxToVw(3);
				border-radius:4px;
				&[data-current] {
					background-color:$c-brand-orange;
				}
			}
		}
		&[data-table-position='top'] {
			flex-direction:column;
		}
		&[data-table-position='right'] {
			flex-direction:row-reverse;
		}
		&[data-table-position='bottom'] {
			flex-direction:column-reverse;
		}
		&[data-table-position='left'] {
			flex-direction:row;
		}
	}

</style>
