<template>
	<div
		data-component="card"
		:data-index="index"
		:data-player="card.player"
		:data-table-position="getTablePosition"
		:data-dimmed="getIsDimmed"
		:data-inactive="isInactive"
		v-if="!getIsHidden"
		@click="click"
	>
		<component
			:is="getCard"
		/>
		<div
			v-if="getShowDDSTricks"
			data-element="dds-tricks"
			:data-colour="getDDSColour"
			:data-position="getDDSPosition"
		>
			{{ getDDSTricksString }}
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			card: {
				type: Object,
				required: true
			},
			index: {
				type: Number,
				required: true
			},
			isInDummy: {
				type: Boolean,
				default: false
			},
			isInactive: {
				type: Boolean,
				default: false
			}
		},
		computed: {
			getTablePosition () {
				if (!this.getCardIsInCurrentTrick) {
					return false;
				}
				if (!this.card.player) {
					return false;
				}
				return this.$store.getters['simulator/getTablePositionByPosition']({
					position: this.card.player
				});
			},
			getCardsAreHighlighted () {
				return this.$store.getters['simulator/getCardsAreHighlighted'];
			},
			getIsDimmed () {
				if (this.getIsHidden) {
					return false;
				}
				if (this.getIsFaceDown) {
					return false;
				}
				if (!this.getCardsAreHighlighted) {
					return false;
				}
				if (this.getIsHighlighted) {
					return false;
				}
				return true;
			},
			getIsHidden () {
				if (this.getCardIsInCurrentTrick) {
					return false;
				}
				return this.$store.getters['simulator/getCardIsHiddenByCardName']({
					cardName: this.card.name
				});
			},
			getIsFaceDown () {
				if (this.getCardIsInCurrentTrick) {
					return false;
				}
				return this.$store.getters['simulator/getCardIsFaceDownByCardName']({
					cardName: this.card.name
				});
			},
			getCardIsInCurrentTrick () {
				return this.$store.getters['simulator/getCardIsInCurrentTrickByCardName']({
					cardName: this.card.name
				});
			},
			getCardFilename () {
				if (this.getIsFaceDown) {
					return 'back-solid-grey';
				}
				return this.card.name.toLowerCase();
			},
			getIsHighlighted () {
				return this.$store.getters['simulator/getCardIsHighlightedByCardName']({
					cardName: this.card.name
				});
			},
			getCard () {
				return require(`@/assets/cards/two-indices/${this.getCardFilename}.svg?inline`);
			},
			getShowDDSTricks () {
				if (!this.$store.getters['simulator/getDDSScoresAreVisible']) {
					return false;
				}
				if (this.getIsFaceDown) {
					return false;
				}
				if (this.getDDSTricks === false ||
						!this.getDDSColour) {
					return false;
				}
				return true;
			},
			getDDSTricks () {
				if (this.card?.dds?.tricks === undefined) {
					return false;
				}
				return this.card.dds.tricks;
			},
			getDDSTricksString () {
				if (this.getDDSTricks === false) {
					return false;
				}
				if (this.getDDSTricks === 0) {
					return '=';
				}
				return this.getDDSTricks;
			},
			getDDSColour () {
				if (this.getDDSTricks === false) {
					return false;
				}
				if (this.getDDSTricks >= 0) {
					return 'green';
				}
				return 'red';
			},
			getDDSPosition () {
				if (!this.getShowDDSTricks) {
					return false;
				}
				if (this.isInDummy) {
					return 'top right';
				}
				return 'top left';
			}
		},
		methods: {
			click () {
				if (this.isInactive) {
					return;
				}
				if (this.$store.getters['simulator/getHighlightingModeIsEnabled']) {
					this.$store.dispatch('simulator/toggleCardIsHighlighted', {
						cardName: this.card.name
					});
					return;
				}
				if (this.$store.getters['simulator/getIsStudyMode']) {
					this.$store.dispatch('simulator/playStudyCardByName', {
						cardName: this.card.name
					});
					return;
				}
				this.$store.dispatch('simulator/playCardByName', {
					cardName: this.card.name
				});
			}
		}
	};
</script>

<style lang="scss" scoped>

	[data-component='card'] {
		position:relative;
		opacity:1;
		background:$c-white;
		position:relative;
		z-index:0;
		user-select:none;
		border-radius:$card-border-radius;
		border:1px solid $c-brand-grey-lighter-2;
		::v-deep svg {
			display:block;
			width:100%;
			height:auto;
			opacity:1;
		}
		[data-element='dds-tricks'] {
			position:absolute;
			display:flex;
			align-items:center;
			justify-content:center;
			background-color:#000;
			font-size:rem(18);
			line-height:1;
			font-weight:700;
			height:rem(22);
			border-radius:4px;
			&[data-colour='green'] {
				background-color:$c-brand-green-lighter-4;
			}
			&[data-colour='red'] {
				background-color:$c-brand-red-lighter-2;
			}
			&[data-position='top left'] {
				width:22%;
				left:3%; top:35%;
			}
			&[data-position='top right'] {
				width:24%;
				right:3%; top:3.5%;
			}
		}
		&[data-dimmed] {
			svg {
				opacity:0.2;
			}
		}
		&[data-inactive] {
			cursor: default !important;
		}
	}

</style>
