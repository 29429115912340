<template>
	<div data-component="simulator">
		<app-logo
			size="sm"
			:linkToHome="false"
			:linkToLesson="getAppLogoLinksToLesson"
			@click.native="clickAppLogo"
		/>
		<transition-group name="fade" mode="out-in">
			<div
				v-if="getIsLoading"
				key="loader"
				data-element="loader"
			/>

			<div
				v-else
				key="simulator-content"
				data-element="simulator-content"
			>
				<game-info v-if="getShowGameInfo" />
				<controls v-if="getShowControls" />
				<highlighting-alert />

				<score />
				<bid-history />

				<player position="N" />
				<player position="E" />
				<player position="S" />
				<player position="W" />

				<auction />

				<study-auction />
				<study-controls />

				<trick />

				<parameters @backToLesson="close" />
			</div>
		</transition-group>
	</div>
</template>

<script>

	import AppLogo              from '@/components/ui/header/AppLogo';
	import GameInfo             from '@/components/simulator/GameInfo';
	import Controls             from '@/components/simulator/Controls';
	import HighlightingAlert    from '@/components/simulator/ui/HighlightingAlert';
	import Score                from '@/components/simulator/Score';
	import BidHistory           from '@/components/simulator/bidding/BidHistory';
	import Player               from '@/components/simulator/player/Player';
	import Auction              from '@/components/simulator/bidding/Auction';
	import StudyAuction         from '@/components/simulator/bidding/StudyAuction';
	import StudyControls        from '@/components/simulator/StudyControls';
	import Trick                from '@/components/simulator/Trick';
	import Parameters           from '@/components/simulator/parameters/Parameters';
	import routeParams          from '@/mixins/routeParams';
	import {
		SIMULATOR_MODES,
		POSITIONS_SHORT,
		DEFAULT_PLAYER_POSITION
	} from '@/consts';

	export default {
		layout: 'simulator',
		components: {
			AppLogo,
			GameInfo,
			Controls,
			HighlightingAlert,
			Score,
			BidHistory,
			Player,
			Auction,
			StudyControls,
			StudyAuction,
			Trick,
			Parameters
		},
		mixins: [routeParams],
		props: {
			mode: {
				type: [String, undefined],
				validator (value) {
					return Object.values(SIMULATOR_MODES).includes(value);
				},
				default: SIMULATOR_MODES.PREVIEW
			},
			gameId: {
				type: [String, undefined],
				default: undefined
			},
			gameIds: {
				type: Array,
				default () {
					return [];
				}
			},
			playerPosition: {
				type: [String, undefined],
				validator (value) {
					return Object.values(POSITIONS_SHORT).includes(value);
				},
				default: DEFAULT_PLAYER_POSITION
			},
			playersPositions: {
				type: Array,
				validator (value) {
					return value.every(pos => Object.values(POSITIONS_SHORT).includes(pos));
				},
				default () {
					return [];
				}
			},
			gameState: {
				type: Object,
				default: undefined
			},
			gameStates: {
				type: [Array, undefined],
				default: undefined
			},
			gameStateIndex: {
				type: [Number, undefined],
				default: undefined
			},
			bidExplanations: {
				type: Array,
				default () {
					return [];
				}
			},
			bidHints: {
				type: Array,
				default () {
					return [];
				}
			},
			declarerHint: {
				type: String,
				default: ''
			},
			bidExplanationsList: {
				type: Array,
				default () {
					return [];
				}
			},
			bidHintsList: {
				type: Array,
				default () {
					return [];
				}
			},
			declarerHintsList: {
				type: Array,
				default () {
					return [];
				}
			},
			openingLead: {
				type: [String, undefined],
				default: null
			},
			openingLeadsList: {
				type: Array,
				default () {
					return [];
				}
			},
			claimParameters: {
				type: Array,
				default () {
					return [];
				}
			},
			claimParametersList: {
				type: Array,
				default () {
					return [];
				}
			},
			returnRoute: {
				type: String,
				default: undefined
			},
			showCloseButton: {
				type: Boolean,
				default: true
			}
		},
		computed: {
			getIsLoading () {
				return this.$store.getters['simulator/getIsLoading'];
			},
			getAppLogoLinksToLesson () {
				return !this.showCloseButton;
			},
			getShowControls () {
				return this.$store.getters['simulatorControls/getControlsIsVisibleForMode'](this.mode);
			},
			getShowGameInfo () {
				return this.$store.getters['simulatorControls/getGameInfoIsVisibleForMode'](this.mode);
			}
		},
		mounted () {
			this.setReturnRoute();
			this.loadGame();
		},
		created () {
			this.$store.commit('simulator/setLessonId', { lessonId: this.getLessonId || null });
		},
		methods: {
			close () {
				this.$emit('close');
				this.leaveGame();
			},
			setReturnRoute () {
				if (!this.returnRoute) {
					return;
				}
				this.$store.commit('simulatorControls/setReturnRoute', {
					returnRoute: this.returnRoute
				});
			},
			leaveGame () {
				this.$store.dispatch('simulator/leaveGame');
				this.$store.dispatch('simulatorParameters/clearParameters');
				this.$store.dispatch('simulator/resetGameState');
			},
			loadGame () {
				if (!this.gameState &&
						!this.gameStates) {
					return;
				}
				if (this.gameState) {
					this.$store.dispatch('simulator/loadGameState', {
						mode: this.mode,
						gameId: this.gameId,
						gameState: this.gameState,
						playerPosition: this.playerPosition,
						bidExplanations: this.bidExplanations,
						bidHints: this.bidHints,
						declarerHint: this.declarerHint,
						openingLead: this.openingLead,
						claimParameters: this.claimParameters
					});
					return;
				}
				this.$store.dispatch('simulator/loadGameStates', {
					mode: this.mode,
					gameIds: this.gameIds,
					gameStates: this.gameStates,
					gameStateIndex: this.gameStateIndex,
					playersPositions: this.playersPositions,
					bidExplanationsList: this.bidExplanationsList,
					bidHintsList: this.bidHintsList,
					declarerHintsList: this.declarerHintsList,
					openingLeadsList: this.openingLeadsList,
					claimParametersList: this.claimParametersList
				});
			},
			clickAppLogo () {
				if (!this.showCloseButton) {
					return false;
				}
				this.close();
			}
		}
	};

</script>

<style lang="scss" scoped>
	[data-component='simulator'] {
		overflow:hidden;
		position:fixed;
		top:0; right:0;
		left:0; bottom:0;
		min-height:100vh;
		min-height:-webkit-fill-available;
		width:100vw;
		background-color:$c-brand-blue-lighter-4;
		&:before {
			content:'';
			position:absolute;
			top:12px;
			right:12px;
			bottom:12px;
			left:12px;
			display:block;
			border:3px solid $c-brand-red;
			border-radius:4px;
			pointer-events:none;
		}
		&:after {
			content:'';
			position:absolute;
			top:17px;
			right:17px;
			bottom:17px;
			left:17px;
			display:block;
			border:5px solid $c-brand-blue;
			pointer-events:none;
		}
		[data-element='loader'] {
			@include loader(9px);
			position: absolute;
			top: 50%;
			left: 50%;
			margin-top: -5em;
			margin-left: -5em;
			z-index: 1;
		}
		[data-element='simulator-content'] {
			height: 100%;
			width: 100%;
		}
		[data-component='highlighting-alert'] {
			position:absolute;
			bottom:20px; right:19px; left:19px;
		}
		[data-component='app-logo'] {
			position:fixed;
			bottom:rem(36);
			right:rem(36);
		}
		[data-component='game-info'] {
			position: absolute;
			right:rem(36);
			bottom:rem(96);
		}
		[data-component='auction'],
		[data-component='study-auction'] {
			position:absolute;
			left:50%; top:50%;
			transform:translate(-50%,-50%);
		}
		[data-component='study-controls'] {
			position: absolute;
			left:50%; top:50%;
			transform: translate(-50%, rem(28 * 4)); // Below the auction
		}
		[data-component='bid-history'] {
			position:absolute;
			left:rem(36);
			top:rem(36);
		}
		[data-component='trick'] {
			position:absolute;
			left:50%; top:50%;
			transform:translate(-50%,-50%);
		}
		[data-component='controls'] {
			position:absolute;
			bottom:rem(36); left:rem(36);
		}
		[data-component='score'] {
			position:absolute;
			top:calc(50vh);
			margin-top:pxToVw(95);
			left:calc(50vw);
			margin-left:pxToVw(180);
		}
		[data-component='player'] {
			position:absolute;
			&[data-table-position='top'],
			&[data-table-position='bottom'] {
				left:50%;
				transform:translate(-50%,0);
			}
			&[data-table-position='right'],
			&[data-table-position='left'] {
				top:50%;
				transform:translate(0,-50%);
			}
			&[data-table-position='top'] {
				top:pxToVw(32);
			}
			&[data-table-position='right'] {
				right:pxToVw(100);
			}
			&[data-table-position='bottom'] {
				bottom:pxToVw(32);
			}
			&[data-table-position='left'] {
				left:pxToVw(100);
			}
		}
	}
</style>
