<template>
	<div data-component="dummy">
		<component
			:is="getComponent"
			:cardsInSuits="getCardsInSuits"
		/>
	</div>
</template>

<script>

	import DummyColumns  from '@/components/simulator/player/DummyColumns';
	import DummyRows     from '@/components/simulator/player/DummyRows';

	export default {
		components: {
			DummyColumns,
			DummyRows
		},
		props: {
			position: {
				type: String,
				required: true,
				validator: (value) => {
					return [
						'N',
						'E',
						'S',
						'W'
					].includes(value);
				}
			},
			tablePosition: {
				type: String,
				required: true
			}
		},
		data: () => ({
			suits: [
				'S',
				'H',
				'D',
				'C'
			]
		}),
		computed: {
			getComponent () {
				if (this.getDummyLayout === 'columns') {
					return 'DummyColumns';
				}
				return 'DummyRows';
			},
			getDummyLayout () {
				return this.$store.getters['simulator/getDummyLayoutByPosition']({
					position: this.position
				});
			},
			getCardsInSuits () {
				return this.$store.getters['simulator/getUnplayedDummyCardsByPosition']({
					position: this.position
				});
			}
		}
	};

</script>

<style lang="scss" scoped>

	[data-component='dummy'] {
		width:100%;
	}

</style>
